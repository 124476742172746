//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PlanModel } from '@/modules/plan/plan-model';
import { i18n, getLanguageCode } from '@/i18n';
import { routerAsync } from '@/app-module';

const { fields } = PlanModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.description,
  fields.type,
  fields.numberOfDays,
  fields.programs,
]);

export default {
  name: 'app-plan-edit-page',

  props: ['id'],

  async created() {
    await this.doFind(this.id);
    this.record.numberOfDays = `${this.record.numberOfDays}`
    this.model = formSchema.initialValues(this.record);

    for (let index = 0; index < this.model.programs.length; index++) {
      this.programList[index+1] = this.model.programs[index].places;
    }
  },

  data() {
    return {
      time: 0,
      model: null,
      language: getLanguageCode(),
      visibleImageDialog: false,
      visibleTimeDialog: false,
      selectedPlaceID: null,
      selectedProgramKey: null,
      selectedPlaceIndex: null,
      placeImages: [],
      programList: {},
      places: [],
      // images: [],
    };
  },
  computed: {
    ...mapGetters({
      record: 'plan/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'plan/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    selectImageDimensions() {
      if (this.is_screen_xs) {
        return '50px'
      } else {
        return '105px'
      }
    },
    images() {
      let selectedImgs = []
      for (const property in this.programList) {
        this.programList[property].forEach(place => {
          const img = place.place.placePictures.filter(img => img.publicUrl == place.selectedImage)
          if (img.length) {
            selectedImgs.push({
              id: img[0].id,
              name: img[0].name,
              privateUrl: img[0].privateUrl,
              publicUrl: img[0].publicUrl,
            })
          }
        });
      }
      return selectedImgs
    },
  },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doUpdate: 'plan/form/doUpdate',
      // doFetchCities: 'city/list/doFetch',
      // doFetchPlaces: 'place/list/doFetch',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    // presenter(record, fieldName) {
    //   return PlanModel.presenter(record, fieldName);
    // },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      routerAsync().push(`/plan/${this.model.id}`);
    },
    onOpenModal(type, key, index, id) {
      // this.placeImages = this.programList[key][index].placePictures.map(item => { return {id: item.id, src: item.publicUrl} })
      this.placeImages = this.programList[key][index].place.placePictures.map(item => { return {id: item.id, src: item.publicUrl} })
      this.selectedPlaceID = id;
      this.selectedPlaceIndex = index;
      this.selectedProgramKey = key;
      // this.modalVisible = true;
      switch(type) {
        case 'image':
          this.visibleImageDialog = true;
          break;
        case 'time':
          this.time = this.programList[key][index].averageTime
          this.visibleTimeDialog = true;
          break;
        default:
      }
    },
    onModalClose() {
      this.placeImages = [];
      this.time = 0;
      this.selectedPlaceID = null;
      this.selectedPlaceIndex = null;
      this.selectedProgramKey = null;
      this.visibleImageDialog = false;
      this.visibleTimeDialog = false;
      // setTimeout(() => {
      //   this.modalVisible = false;
      // }, 200);
    },
    
    //#region [ Select Image Dialog ]
    doSaveImage() {
      this.onModalClose();
    },
    onSelectImage(val) {
      console.log(val)
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['selectedImage'] = val.src
    },
    //#endregion

    //#region [ Set Time Dialog ]
    doSaveTime() {
      this.programList[this.selectedProgramKey][this.selectedPlaceIndex]['averageTime'] = this.time
      this.onModalClose();
    },
    addTime() { this.time++ },
    removeTime() { this.time != 0 ? this.time-- : 0 },
    //#endregion
        
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);

      values.numberOfDays = parseInt(this.model.numberOfDays);
      values.programs = Object.keys(this.programList).map(key => {
        return {
          name: key,
          places: this.programList[key].map(item => {
            return {
              id: item['id'],
              selectedImage: item['selectedImage'] ? item['selectedImage'] : '',
              averageTime: item['averageTime'] ? item['averageTime'] : 0,
            }
          })
        }
      })
      if (this.isEditing) {
        return await this.doUpdate({
          id,
          values,
        });
      } else {
        let record = await this.doCreate(values);
        return record;
      }
    },

    // following method is REQUIRED
    // (don't change its name --> "show")
    show(type) {
      if (type == 'delete') {
        this.$refs.deleteDialog.show()
      } else if (type == 'time') {
        this.$refs.timeDialog.show()
      }
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.deleteDialog.hide()
      this.$refs.timeDialog.hide()
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },

    onOKClick() {
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog
      // this.$emit('ok')
      // or with payload: this.$emit('ok', { ... })

      this.doDelete()
      // then hiding dialog
      this.hide()
    },

    onCancelClick () {
      // we just need to hide dialog
      this.hide()
    },
    // doOpenModal() {
    //   this.dialogVisible = true;
    // },
    // onModalClose() {
    //   this.dialogVisible = false;
    // },
    doDelete() {
      // this.onModalClose();
      this.hide();
    },
    // addTime() { this.time++ },
    // removeTime() { this.time != 0 ? this.time-- : 0 },
  },
  // watch: {
  //   programList() {
  //     let selectedImgs = []
  //     for (const property in this.programList) {
  //       this.programList[property].forEach(place => {
  //         const imgUrl = place.selectedImage
  //         if (imgUrl) {
  //           selectedImgs.push({publicUrl: imgUrl})
  //         }
  //       });
  //     }
  //     return selectedImgs
  //   }
  // },
};
